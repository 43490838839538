.title-link a {
  color: #fff;
}

.title-link a:visited {
  color: #fff;
}

.logo-link {
  color: #FFF !important;
}

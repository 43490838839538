.headline {
  font-size: 18px;
  font-weight: normal;
}
.detail {
  font-weight: bold;
  font-size: 20px;
}
.center {
  text-align: center;
}
.strong {
  font-weight: bold;
}
.bounding-box {
  max-height: 250px;
  overflow: hidden auto;
}
.games-map {
  border: 1px solid #000;
}
.event-card {
  text-align: center;
  min-width: 200px;
}
.MuiCardContent-root {
  padding: 5px !important;
  background: #004b84; 
  color: #FFF;
  font-weight: bold;
  border: 1px solid #000;
}
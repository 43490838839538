.chosen-file {
  margin: 10px;
  width: 100%;
}

.practice-group-image {
  width:150px;
  height:150px;
  border-radius:10%;
  border: 2px solid #000;
  filter: drop-shadow(2px 5px 3px #000);
  
}

.main-container {
  margin-right: 3px;
  }
  
.main-container {
  @media only screen and (min-width: 768px) {
    margin-right: 0px;
  } 
}

.chosen-file {
  position: relative;
  width: 310px;
}

.chosen-file .remove-file {
  position: absolute;
  top: 0;
  right: 0;
}

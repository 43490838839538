.form-input-container {
  margin: 15px 0px 20px 0px;
  padding-right: 20px;
}

.test{
  filter: drop-shadow(2px 5px 3px #000);
}

.practice-group-modal-image {
  margin-left: 10px;
  margin-bottom: 10px;
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 10%;
  border: 1px solid #000;
  filter: drop-shadow(2px 5px 3px #000);
}
path {
  pointer-events: all;
}

path:hover {
  opacity: 0.50;
  cursor: pointer;
}

svg {
  display: inline-block;
  vertical-align: middle;
}
* {
  outline: none;
}

.state:hover {
  z-index: 99999;
  stroke: #fff;
  fill: #3e4a94;
  cursor: pointer
}

.state-text > .text {
  z-index: 99999;
  stroke: #fff;
  fill: #386f94;
}

.text {
  cursor: pointer;
}

p {
  background: #FFF;
  border: none;
}

.MuiCardContent-root {
  background: #FFF;
  border: none;
  color: #000;
}

.practice-group-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: "50%";
  margin-top: 10px;
}

.map-container {
  display: none;
  }
  
.map-container {
  @media only screen and (min-width: 768px) {
    display: block;
  } 

}

.paper-padding {
  padding: 10px 20px 20px 10px;
}

.form-margin {
  margin: 5px 0px;
}

.games-map {
  width: 100%;
  border: 1px solid #c9c9c9;
}

.class-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
}
.tooltip-text {
  font-size: 16px;
}
.tooltip-headline {
  display: inline-block;
  margin-top: -10px;
  margin-bottom: 10px;
  text-shadow: 2px 2px #000;
}
.tooltip-event {
  text-shadow: 2px 2px #000;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
}
.left {
  float: left;
}
.right {
  float: right;
}
.bold {
  font-weight: bolder;
}
.tooltip-container {
  padding: 10px;
  border: 1px solid #000;
}
.class-tooltip {
  cursor: pointer;
}